import React, { Suspense } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  PrimarySection,
  HeroBlock,
  SecondarySection,
  InviteRegistrationBlock,
  Layout,
} from '../components';
import {
  BackgroundLayout,
  PaddedLayout,
  SectionLayout,
} from '../components/ui';
import {
  SECONDARY_SECTION_ABOUT_DATA,
  SECONDARY_SECTION_CLIENTS_DATA,
  SECONDARY_SECTION_OBJECTS_DATA,
  SECONDARY_SECTION_REQUESTS_DATA,
  SECONDARY_SECTION_TITLES,
} from '../data/layoutData';
import phoneAppInfo from '../images/phoneAppInfo.png';
import phoneClients from '../images/phoneClients.png';
import phoneObjects from '../images/phoneObjects.png';
import phoneRequests from '../images/phoneRequests.png';
import { CheckMark } from '../assets/svg/icon';
import { graphql } from 'gatsby';

const IndexPage = ({ location }) => {
  const { t } = useTranslation();
  return (
    // <Suspense fallback="loading">
    <Layout location={location}>
      <PaddedLayout>
        <PrimarySection>
          <HeroBlock />
        </PrimarySection>
      </PaddedLayout>

      <SectionLayout>
        <PaddedLayout>
          <SecondarySection
            image={phoneAppInfo}
            // alt={t(`${data.heroList.id}`)}
            alt={t(`secondarySectionTitles.about.alt`)}
            title={t(`secondarySectionTitles.about`)}
            data={SECONDARY_SECTION_ABOUT_DATA}
            Icon={CheckMark}
          />
        </PaddedLayout>

        <PaddedLayout>
          <SecondarySection
            image={phoneClients}
            alt={t(`secondarySectionTitles.clients.alt`)}
            title={t(`secondarySectionTitles.clients`)}
            data={SECONDARY_SECTION_CLIENTS_DATA}
            Icon={CheckMark}
            variant="reverse"
          />
        </PaddedLayout>

        <PaddedLayout>
          <SecondarySection
            image={phoneObjects}
            alt={t(`secondarySectionTitles.objects.alt`)}
            title={t(`secondarySectionTitles.objects`)}
            data={SECONDARY_SECTION_OBJECTS_DATA}
            Icon={CheckMark}
          />
        </PaddedLayout>

        <PaddedLayout>
          <SecondarySection
            image={phoneRequests}
            alt={t(`secondarySectionTitles.requests.alt`)}
            title={t(`secondarySectionTitles.requests`)}
            data={SECONDARY_SECTION_REQUESTS_DATA}
            Icon={CheckMark}
            variant="reverse"
            lastChild
          />
        </PaddedLayout>
      </SectionLayout>

      <BackgroundLayout>
        <PaddedLayout>
          <PrimarySection variant="secondary">
            <InviteRegistrationBlock />
          </PrimarySection>
        </PaddedLayout>
      </BackgroundLayout>
    </Layout>
    // </Suspense>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
